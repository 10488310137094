<template>
    <div class="info-page">
        <div class="info-page__cover">
            <div class="cover-content-privacy">
                <h1 class="cover-content-privacy__title">{{ info.page.h1 }}</h1>
            </div>
        </div>

        <LayoutInfoPages :content="info.page.content || ''" />
    </div>
</template>

<script lang="ts" setup>
import type { Api } from '~~/global'

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data: responseData, error } = await useAsyncData('privacy-policy-data', () => {
    return $fetch<Api.Responses.Info.Basic>(endpoints.info.cookies.path, {
        headers: buildHeaders(),
        method: 'GET',
        baseURL,
    }).catch((e) => e.data)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const info = ref(responseData.value)

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

useSeoMeta({
    title: info.value.page.title,
    ogUrl: origin + Route.path,
    ogTitle: info.value.page.title,
    description: info.value.page.description,
    ogDescription: info.value.page.description,
    ogImageAlt: info.value.page.title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.info-page {
    &__cover {
        @apply relative mb-2 h-auto overflow-hidden bg-gradient-to-r from-site-primary-hover via-site-primary to-site-primary-hover-light;
        .cover-content-privacy {
            @apply container p-6;
            &__title {
                @apply w-full text-center text-2xl font-medium text-white;
            }
            &__img {
                @apply hidden w-32 md:block;
            }
        }
    }
}
</style>
